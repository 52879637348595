import React from 'react';

export function getClientPosition(e: MouseEvent | TouchEvent) {
  if ('touches' in e && e.touches.length > 0) {
    return {
      x: e.touches[0].clientX,
      y: e.touches[0].clientY,
    };
  } else if ('clientX' in e) {
    return {
      x: e.clientX,
      y: e.clientY,
    };
  } else {
    throw new Error('Not a Mouse/Touch Event');
  }
}

export default function useDragToScroll() {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const isDown = React.useRef(false);
  const scrollLeft = React.useRef<number | null>(null);
  const startX = React.useRef<number | null>(null);

  React.useEffect(() => {
    if (!ref.current) return;
    const element = ref.current;
    const mouseDown = (event: MouseEvent) => {
      isDown.current = true;
      startX.current = event.pageX - element.offsetLeft;
      scrollLeft.current = element.scrollLeft;
    };
    const mouseLeave = (event: MouseEvent) => {
      isDown.current = false;
    };
    const mouseUp = (event: MouseEvent) => {
      isDown.current = false;
      if (element.scrollLeft > 3) {
        event.stopPropagation();
      }
    };
    const mouseMove = (event: MouseEvent) => {
      if (!isDown.current) return;
      event.preventDefault();
      event.stopPropagation();
      const x = event.pageX - element.offsetLeft;
      const move = (x - (startX?.current || 0)) * 2;
      element.scrollLeft = (scrollLeft?.current || 0) - move;
    };

    element.addEventListener('mousedown', mouseDown);
    element.addEventListener('mouseup', mouseUp);
    element.addEventListener('mouseleave', mouseLeave);
    element.addEventListener('mousemove', mouseMove);
    return () => {
      element.addEventListener('mousedown', mouseDown);
      element.removeEventListener('mouseup', mouseUp);
      element.removeEventListener('mouseleave', mouseLeave);
      element.removeEventListener('mousemove', mouseMove);
    };
  }, []);

  // // https://phuoc.ng/collection/html-dom/drag-to-scroll/
  // const ref = React.useRef<HTMLElement>(null);
  // /** The start position of the user's drag */
  // const startPosition = React.useRef({ top: 0, left: 0, x: 0, y: 0 });
  // /** The user definitely has started dragging */
  // const grabbing = React.useRef(false);
  // /** The user has initiated the drag by clicking/touching down, but it's unclear if this is a drag of click event */
  // const grabbingDubiously = React.useRef(false);
  // /** We will be altering the style of the cursor in the container... which might have their own custom cursor style. This is used to restore that style later. */
  // const originalCursorStyle =
  //   React.useRef<NonNullable<React.CSSProperties['cursor']>>('grab');
  // React.useEffect(() => {
  //   if (!ref.current) return;
  //   const element = ref.current;
  //   element.style.cursor = 'grab';
  //   function onDragStart(e: MouseEvent | TouchEvent) {
  //     // User has pressed down on the container, but hasn't begun moving yet. Just for precaution's sake, we'll memorize the position of their touch and set the Grabbing Dubiously flag to true.
  //     startPosition.current.top = element.scrollTop;
  //     startPosition.current.left = element.scrollLeft;
  //     const { x, y } = getClientPosition(e);
  //     startPosition.current.x = x;
  //     startPosition.current.y = y;
  //     grabbingDubiously.current = true;

  //     // We don't want any double scrolling with other components (especially pull to refresh).
  //     e.stopPropagation();
  //   }
  //   function onDragMove(e: MouseEvent | TouchEvent) {
  //     if (grabbingDubiously.current) {
  //       // User has started moving while they have been dragging; confirm that the user is indeed dragging.
  //       grabbingDubiously.current = false;
  //       grabbing.current = true;
  //       element.classList.add(UseDragToScrollStyles.dragging);
  //       originalCursorStyle.current = element.style.cursor;
  //       element.style.cursor = 'grabbing';
  //     }
  //     if (!grabbing.current) return;
  //     // We don't want any double scrolling with other components (especially pull to refresh).
  //     e.stopPropagation();
  //     const { x, y } = getClientPosition(e);
  //     const dx = x - startPosition.current.x;
  //     const dy = y - startPosition.current.y;
  //     // Scroll the element according to the offset
  //     element.scrollTop = startPosition.current.top - dy;
  //     element.scrollLeft = startPosition.current.left - dx;
  //   }
  //   function onDragEnd(e: MouseEvent | TouchEvent) {
  //     // Stop the drag
  //     element.style.cursor = originalCursorStyle.current;
  //     grabbing.current = false;
  //     element.classList.remove(UseDragToScrollStyles.dragging);
  //     e.preventDefault();
  //     e.stopPropagation();
  //   }

  //   // Mobile already has drag-to-scroll behavior by default on the browsers, no need to register those events.
  //   element.addEventListener('mousedown', onDragStart);
  //   element.addEventListener('mousemove', onDragMove);
  //   element.addEventListener('mouseleave', onDragEnd);
  //   element.addEventListener('mouseup', onDragEnd);
  //   return () => {
  //     element.removeEventListener('mousedown', onDragStart);
  //     element.removeEventListener('mousemove', onDragMove);
  //     element.removeEventListener('mouseleave', onDragEnd);
  //     element.removeEventListener('mouseup', onDragEnd);
  //   };
  // }, []);

  return {
    ref,
  };
}
