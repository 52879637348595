import { useNetwork } from '@mantine/hooks';
import { UseQueryResult } from '@tanstack/react-query';
import { ApiError } from 'common/api/model';
import { isNetworkError } from 'common/api/utils';
import { mapObject } from 'common/utils/iterable';
import Script, { ScriptProps } from 'next/script';
import React from 'react';

import ErrorView, { ErrorViewComponentProps } from './error-view-component';
import CustomErrorViewComponent from './error-view-component/custom';
import LoadingViewComponent, {
  LoadingViewComponentProps,
} from './loading-view-component';
import LoginViewComponent from './login-view-component';
import { MaybeFC, MaybeFCType } from './maybe';
import OfflineViewComponent from './offline-view-component';

interface FetchWrapperSharedProps {
  loadingComponent?: React.ReactNode;
  errorComponent?: React.ReactNode;
  loadingViewComponentProps?: LoadingViewComponentProps;
  errorViewComponentProps?: ErrorViewComponentProps;
}
export interface WrapperProps extends FetchWrapperSharedProps {
  isLoading?: boolean;
  error?: ApiError | boolean | null;
  onRetry?: () => void;
  children: React.ReactNode;
  showOffline?: boolean;
}

export interface UseQueryWrapperProps<T extends object>
  extends FetchWrapperSharedProps {
  isLoading?: boolean;
  query: UseQueryResult<T, ApiError>;
  children: MaybeFCType<T>;
}

export interface JointQueryWrapperProps<T extends Record<string, any>>
  extends FetchWrapperSharedProps {
  isLoading?: boolean;
  queries: {
    [key in keyof T]: UseQueryResult<T[key], ApiError>;
  };
  children: MaybeFCType<T>;
}

export default function FetchWrapperComponent(props: WrapperProps) {
  const {
    isLoading = false,
    error,
    onRetry,
    loadingComponent,
    children,
    errorComponent,
    loadingViewComponentProps,
    errorViewComponentProps,
    showOffline = true,
  } = props;

  const { online } = useNetwork();
  if (isLoading) {
    return (
      loadingComponent || (
        <LoadingViewComponent {...loadingViewComponentProps} />
      )
    );
  } else if (error) {
    if (errorComponent) {
      return errorComponent;
    }

    const errorMessage = Object.prototype.hasOwnProperty.call(error, 'message')
      ? (error as ApiError).message
      : undefined;

    const hasConnectionIssue =
      !online || (!!errorMessage && isNetworkError(errorMessage));

    if (hasConnectionIssue && showOffline) {
      return <OfflineViewComponent refetch={onRetry} />;
    }

    if (typeof error === 'object') {
      const statusCode = error.statusCode;
      const errorViewComponent = (
        <ErrorView
          refetch={onRetry}
          message={error?.message}
          {...errorViewComponentProps}
        />
      );

      if (typeof statusCode === 'undefined') {
        return errorViewComponent;
      }

      if (statusCode === 401) {
        return <LoginViewComponent />;
      }

      return (
        <CustomErrorViewComponent
          statusCode={statusCode}
          refetch={onRetry}
          fallback={errorViewComponent}
        />
      );
    }

    return (
      <ErrorView
        refetch={onRetry}
        message={undefined}
        {...errorViewComponentProps}
      />
    );
  }

  return children;
}

export function UseQueryWrapperComponent<T extends object>(
  props: UseQueryWrapperProps<T>,
): React.ReactElement {
  const { query, children, isLoading, errorComponent, ...rest } = props;

  return (
    <FetchWrapperComponent
      error={query.error}
      isLoading={
        isLoading == null ? query.isLoading : isLoading && !query?.error
      }
      onRetry={query.refetch}
      errorComponent={errorComponent}
      {...rest}
    >
      {!!query?.data && <MaybeFC props={query.data}>{children}</MaybeFC>}
    </FetchWrapperComponent>
  );
}

export function JointQueryWrapperComponent<T extends Record<string, any>>(
  props: JointQueryWrapperProps<T>,
): React.ReactElement {
  const { queries, children, isLoading, errorComponent, ...rest } = props;

  const queryArray = Object.values(queries);
  const error = queryArray.find((query) => query.error)?.error;
  const loading = queryArray.some((query) => query.isLoading);
  const data = mapObject(
    queries as Record<string, UseQueryResult<any, ApiError>>,
    (key, value) => [key, value.data],
  ) as T;
  return (
    <FetchWrapperComponent
      error={error}
      isLoading={isLoading == null ? loading : isLoading && !error}
      onRetry={() => queryArray.forEach((query) => query.refetch())}
      errorComponent={errorComponent}
      {...rest}
    >
      {Object.values(data).every((x) => !!x) && (
        <MaybeFC props={data}>{children}</MaybeFC>
      )}
    </FetchWrapperComponent>
  );
}

interface ScriptLoaderWrapperComponentProps extends FetchWrapperSharedProps {
  src: string;
  id: string;
  scriptProps?: Omit<ScriptProps, 'src' | 'id' | 'children'>;
  children?: React.ReactNode | (() => React.ReactNode);
}

export function ScriptLoaderWrapperComponent(
  props: ScriptLoaderWrapperComponentProps,
) {
  const { src, id, scriptProps, children, ...fetchWrapperProps } = props;
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<ApiError | undefined>(undefined);
  return (
    <>
      <FetchWrapperComponent
        {...fetchWrapperProps}
        isLoading={loading}
        error={error}
      >
        {!loading && error == null
          ? typeof children === 'function'
            ? children()
            : children
          : null}
      </FetchWrapperComponent>
      <Script
        src={src}
        id={id}
        {...scriptProps}
        onReady={() => {
          setLoading(false);
          setError(undefined);
          scriptProps?.onReady?.();
        }}
        onError={(e) => {
          setError({
            message: e?.message ?? e.toString(),
          });
          setLoading(false);
          console.error(e);
          scriptProps?.onError?.(e);
        }}
      />
    </>
  );
}
