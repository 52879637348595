import classNames from 'classnames';
import { AssetPaths } from 'common/constants/assets';
import { NavigationRoutes } from 'common/routes';
import Button from 'components/elements/button';
import Text from 'components/elements/text';
import useKurosimNavigation from 'hooks/use-kurosim-navigation';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import structuralStyles from 'styles/layout.css';

export type UnauthorizeViewType = 'order' | 'referral' | 'card';

export interface UnauthorizeViewProps {
  type: UnauthorizeViewType;
}

export const UnauthorizeView = React.memo((props: UnauthorizeViewProps) => {
  const { type } = props;
  const { t } = useTranslation();

  const { push } = useKurosimNavigation();

  const icon = React.useMemo(() => {
    switch (type) {
      case 'order':
        return AssetPaths.NoData2;
      case 'referral':
        return AssetPaths.EmptyGiftbox;
      case 'card':
        return AssetPaths.CheckEsim;
    }
  }, [type]);

  return (
    <div
      className={classNames(
        structuralStyles.fill({ width: true, height: true }),
        structuralStyles.padding({ horizontal: 16 }),
        structuralStyles.flexbox({
          direction: 'column',
          gap: 24,
          justify: 'center',
        }),
      )}
    >
      <Image
        src={icon}
        alt={t(`error:${type}_need_login_title`)}
        width={240}
        height={240}
      />
      <div
        className={structuralStyles.flexbox({ direction: 'column', gap: 8 })}
      >
        <Text ta="center" textVariant="body1Semibold">
          {t(`error:${type}_need_login_title`)}
        </Text>
        <Text
          ta="center"
          textVariant="body2Regular"
          textColor="foregroundTertiary"
        >
          {t(`error:${type}_need_login_description`)}
        </Text>
      </div>
      <Button fullWidth onClick={() => push(NavigationRoutes.Login)}>
        {t('common:login')}
      </Button>
    </div>
  );
});
